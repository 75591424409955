import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/about.module.css'
import * as mobileStyles from '../styles/mobile/about.module.css'
import { useWindowDimensions } from '../components/Window'
import Seo from '../components/SEO'

const About = () => {
  const { width } = useWindowDimensions()

  if (width > 850)
    return (
      <Layout>
        <Seo title={`About`} />
        <div className={styles.car_block}>
          <h1 className={styles.header}>Our Vision.</h1>
          <h2 className={styles.sub_header}>A smart and safe mindset is the key to success on the road.</h2>
          <p className={styles.text_body}>
            Some new learners may think that mastery of the mechanics is all you need to become a good driver.
            I believe that successful drivers are the ones who also have a smart and safe mindset.
          </p>
          <img src="/op.jpg" alt="prius rear" className={styles.car_image} />
          <p className={styles.text_body}>
            I believe a smart and safe mindset leads to an understanding of the road,
            that can help you approach challenging traffic situations and navigate towards safer outcomes.
            My vision is to ensure that my students become drivers who can ensure the safety of both themselves and others on the road.
          </p>
        </div>
        <div className={styles.profile_block}>
          <img src="/alvin.jpg" alt="alvin pfp" className={styles.profile_image} />
          <h2 className={styles.sub_header} style={{ paddingTop: '2em' }}>I have been a driving instructor for over 10 years.</h2>
          <p className={styles.text_body} style={{ marginLeft: '2.25em' }}>
            I strive to bring out my students' confidence and comfort on the road.
            My decades of experience have taught me what skills are most important when it comes to driving safely in the Lower Mainland.
          </p>
        </div>
      </Layout >
    )
  else
    return (
      <Layout>
        <Seo title={`About`} />
        <div className={mobileStyles.car_block}>
          <h1 className={mobileStyles.header}>Our Vision.</h1>
          <h2 className={mobileStyles.sub_header}>A smart and safe mindset is the key to success on the road.</h2>
          <p className={mobileStyles.text_body}>
            Some new learners may think that mastery of the mechanics is all you need to become a good driver.
            I believe that successful drivers are the ones who also have a smart and safe mindset.
          </p>
          <img src="/op.jpg" alt="prius rear" className={mobileStyles.car_image} />
          <p className={mobileStyles.text_body}>
            I believe a smart and safe mindset leads to an understanding of the road,
            that can help you approach challenging traffic situations and navigate towards safer outcomes.
            My vision is to ensure that my students become drivers who can ensure the safety of both themselves and others on the road.
          </p>
        </div>
        <div className={mobileStyles.car_block}>
          <img src="/alvin.jpg" alt="alvin pfp" className={mobileStyles.profile_image} />
          <h2 className={mobileStyles.sub_header}>I have been a driving instructor for over 10 years.</h2>
          <p className={mobileStyles.text_body}>
            I strive to bring out my students' confidence and comfort on the road.
            My decades of experience have taught me what skills are most important when it comes to driving safely in the Lower Mainland.
          </p>
        </div>
      </Layout >
    )

}

export default About